import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from './auth-guard';

Vue.use(VueRouter)

export default new VueRouter({
    mode: "history",
    routes: [

        { 
            path: "/",
            name: "Login ",
            component: () => import('@/views/User/Login/Login'),
            meta: { showHeader: false,showFooter: true},
            
        },

          // == Home ==
          { 
            path: "/Home",
            name: "Home",
            component: () => import('@/views/Home'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Mapviewer",
            name: "Map Viewer",
            component: () => import('@/views/Map/Mapviewer'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/MapviewerOld",
            name: "Map Viewer Old",
            component: () => import('@/views/Map/MapviewerOld'),
            meta: { showHeader: true,showFooter: false},
            
        },

        //Map Mira Test

        // { 
        //     path: "/mapviewer2",
        //     name: "Map Viewer",
        //     component: () => import('@/views/Map/Mapviewer'),
        //     meta: { showHeader: true,showFooter: false},
            
        // },

        { 
            path: "/dashboard2",
            name: "Dashboard 2",
            component: () => import('@/views/Map/Dashboard'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Dashboard",
            name: "Dashboard",
            component: () => import('@/views/Map/Dashboard'),
            meta: { showHeader: true,showFooter: false},
            
        },


        //Request API Page

        { 
            path: "/requestAPI/requestApiList",
            name: "Request API List",
            component: () => import('@/views/RequestAPI/RequestApiList'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/requestAPI/requestApiForm",
            name: "Request API Form",
            component: () => import('@/views/RequestAPI/RequestApiForm'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/requestAPI/stationList",
            name: "API Station List",
            component: () => import('@/views/RequestAPI/StationList'),
            meta: { showHeader: true,showFooter: false},
            
        },


        //Alerting Page

        { 
            path: "/Alert/Exceedance",
            name: "Exceedence",
            component: () => import('@/views/Alert/Exceedance'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Alert/Transmission",
            name: "Transmission",
            component: () => import('@/views/Alert/Transmission'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Alert/ExceedanceByParameter",
            name: "Exceedance By Parameter",
            component: () => import('@/views/Alert/ExceedanceByParameter'),
            meta: { showHeader: true,showFooter: false},
            
        },
        

        //Reporting Page

        { 
            path: "/Report/WaterQualityData",
            name: "Water Quality Data",
            component: () => import('@/views/Report/WaterQualityData'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/DailyReport",
            name: "Daily Report",
            component: () => import('@/views/Report/DailyReport'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/MonthlyReport",
            name: "Monthly Report",
            component: () => import('@/views/Report/MonthlyReport'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/YearlyReport",
            name: "Yearly Report",
            component: () => import('@/views/Report/YearlyReport'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/QueryOptions",
            name: "Query Options",
            component: () => import('@/views/Report/QueryOptions'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/RawData",
            name: "Raw Data",
            component: () => import('@/views/Report/RawData'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/BaselineStudyReport",
            name: "Baseline Study Report",
            component: () => import('@/views/Report/BaselineStudyReport'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Report/InvestigateStudyReport",
            name: "Investigate Study Report",
            component: () => import('@/views/Report/ISReport'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/Maintenance",
            name: "Maintenance",
            component: () => import('@/views/Operational/Maintenance'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/MaintenanceUpload",
            name: "Maintenance Upload",
            component: () => import('@/views/Operational/MaintenanceUpload'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/Calibration",
            name: "Calibration",
            component: () => import('@/views/Operational/Calibration'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/CalibrationUpload",
            name: "Calibration Upload",
            component: () => import('@/views/Operational/CalibrationUpload'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/QAQCSecLevel",
            name: "QAQC Second Level",
            component: () => import('@/views/Operational/QAQCSecLevel'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/Validation",
            name: "Validation",
            component: () => import('@/views/Operational/Validation'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/Operational/InvestigateStudyDataEntry",
            name: "Investigate Study Data Entry",
            component: () => import('@/views/Operational/ISDataEntry'),
            meta: { showHeader: true,showFooter: false},
            
        },

        { 
            path: "/CMSReporting/Reporting",
            name: "CMS Reporting",
            component: () => import('@/views/CMSReporting/Reporting'),
            meta: { showHeader: true,showFooter: false},
            
        },

        //User Page

        { 
            path: "/userProfile/userProfile",
            name: "User Profile",
            component: () => import('@/views/UserProfile/UserProfile'),
            meta: { showHeader: true,showFooter: false},
            
        },

        //System Admin Page

        { 
            path: "/systemAdmin/userManagement/userList",
            name: "User List - User Management",
            component: () => import('@/views/SystemAdmin/UserManagement/UserList'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },
        
        { 
            path: "/systemAdmin/userManagement/addUser",
            name: "Add User - User Management",
            component: () => import('@/views/SystemAdmin/UserManagement/AddUser'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/stationManagement/stationList",
            name: "Station List - Station Management",
            component: () => import('@/views/SystemAdmin/StationManagement/StationList'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/StationManagement/addStation",
            name: "Add Station - Station Management",
            component: () => import('@/views/SystemAdmin/StationManagement/AddStation'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        //Interest Page

        { 
            path: "/systemAdmin/pointOfInterest/ListInterest",
            name: "Interest List - Point Of Interest Management",
            component: () => import('@/views/SystemAdmin/PointOfInterest/ListInterest'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/pointOfInterest/addInterest",
            name: "Add Interest - Point Of Interest Management",
            component: () => import('@/views/SystemAdmin/PointOfInterest/AddInterest'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },
        

        //Parameter Page

        { 
            path: "/systemAdmin/parameterManagement/parameterList",
            name: "Parameter List - Parameter Management",
            component: () => import('@/views/SystemAdmin/ParameterManagement/ParameterList'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/parameterManagement/addParameter",
            name: "Add Parameter - Parameter Management",
            component: () => import('@/views/SystemAdmin/ParameterManagement/AddParameter'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/parameterManagement/setMinMax",
            name: "Set Min Max - Parameter Management",
            component: () => import('@/views/SystemAdmin/ParameterManagement/SetMinMax'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        //Data Page

        { 
            path: "/systemAdmin/manualDataUpdate/dataUpdate",
            name: "Data Update - Manual Data Update",
            component: () => import('@/views/SystemAdmin/ManualDataUpdate/DataUpdate'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        // Maintenance Page

        { 
            path: "/systemAdmin/maintenanceManagement/dataEntry",
            name: "Data Entry Maintenance - Maintenance Management",
            component: () => import('@/views/SystemAdmin/MaintenanceManagement/DataEntry'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        { 
            path: "/systemAdmin/maintenanceManagement/onGoing",
            name: "On Going Maintenance - Maintenance Management",
            component: () => import('@/views/SystemAdmin/MaintenanceManagement/OnGoing'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },
        

        { 
            path: "/systemAdmin/maintenanceManagement/summary",
            name: "Summary - Maintenance Management",
            component: () => import('@/views/SystemAdmin/MaintenanceManagement/Summary'),
            meta: { showHeader: true, showLeftPanel: true},
            beforeEnter: AuthGuard, 
        },

        //Calibration Page

        { 
            path: "/systemAdmin/calibrationManagement/dataEntry",
            name: "Data Entry Calibration - Calibration Management",
            component: () => import('@/views/SystemAdmin/CalibrationManagement/DataEntry'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/calibrationManagement/onGoing",
            name: "On Going Calibration - Calibration Management",
            component: () => import('@/views/SystemAdmin/CalibrationManagement/OnGoing'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/calibrationManagement/summary",
            name: "Historical Summary - Calibration Management",
            component: () => import('@/views/SystemAdmin/CalibrationManagement/Summary'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        //Request Data Page

        { 
            path: "/systemAdmin/requestData/inbox",
            name: "Inbox - Request Data (API)",
            component: () => import('@/views/SystemAdmin/RequestData/Inbox'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },
        
        { 
            path: "/systemAdmin/requestData/requestList",
            name: "Request List - Request Data (API)",
            component: () => import('@/views/SystemAdmin/RequestData/RequestList'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        //Audit Log Page
        
        { 
            path: "/systemAdmin/AuditLog/userLog",
            name: "User Log - Audit Log",
            component: () => import('@/views/SystemAdmin/AuditLog/UserLog'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/AuditLog/stationLog",
            name: "Station Log - Audit Log",
            component: () => import('@/views/SystemAdmin/AuditLog/StationLog'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },

        { 
            path: "/systemAdmin/AuditLog/apiLog",
            name: "API Log - Audit Log",
            component: () => import('@/views/SystemAdmin/AuditLog/ApiLog'),
            meta: { showHeader: true,showFooter: false,showLeftPanel:true},
            
        },


    ]
});
