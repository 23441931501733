var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.meta.showHeader)?_c('div',[_c('v-app-bar',{staticClass:"class-appbar",attrs:{"id":"app-header","clipped-right":"","clipped-left":"","app":"","elevation":"1"}},[_c('v-app-bar-nav-icon',{staticClass:"pa-3 class-logo"},[_c('v-img',{attrs:{"src":require("../assets/img/logo_rasmi_jmg.png"),"height":"50","contain":""},on:{"click":function($event){return _vm.goToPage('/Mapviewer')}}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-toolbar-title',{staticClass:"class-title"},[_c('h4',[_vm._v("MiQIMS Mines & Quarry Integrated Monitoring System")])]):_c('v-toolbar-title',{staticClass:"class-title-mobile"},[_c('h3',[_vm._v("MiQIMS")])]),_c('v-spacer'),_c('div',{staticClass:"class-icongroup pa-2"},[_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""},on:{"click":function($event){return _vm.openPdf()}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"white","role":"img"}},[_vm._v("mdi-book-open-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("User Manual")])])]}}],null,false,1423221238)}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""},on:{"click":function($event){return _vm.goToPage('/requestAPI/requestApiList')}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"white","role":"img"}},[_vm._v("mdi-access-point-network")])],1)]}}],null,true)},[_c('span',[_vm._v("Request API")])])]}}],null,false,3750056236)}):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{staticClass:"cust-menu-header",staticStyle:{"z-index":"50"},attrs:{"origin":"center","transition":"scale-transition","offset-y":"","content-class":"mt-4 cust-menu-header"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""}},'v-btn',attrs,false),Object.assign({}, tooltip, on)),[_c('v-icon',{attrs:{"color":"white","role":"img"}},[_vm._v("mdi-bell-alert")])],1)]}}],null,true)},[_c('span',[_vm._v("Alert")])])]}}],null,false,927094531)},[_c('v-list',{staticClass:"list-inChild py-0",attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-subheader',{staticClass:"subHeaderList"},[_vm._v("Alert")]),_vm._l((_vm.alertItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"cust-menu-report-items",attrs:{"to":item.url}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)})],2)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{staticClass:"cust-menu-header",staticStyle:{"z-index":"50"},attrs:{"origin":"center center","transition":"scale-transition","offset-y":"","content-class":"mt-4 cust-menu-header"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""}},'v-btn',attrs,false),Object.assign({}, tooltip, on)),[_c('v-icon',{attrs:{"color":"white","role":"img"}},[_vm._v("mdi-file-document")])],1)]}}],null,true)},[_c('span',[_vm._v("Report")])])]}}],null,false,1228169827)},[_c('v-list',{staticClass:"list-inChild py-0"},[_c('v-subheader',{directives:[{name:"show",rawName:"v-show",value:(_vm.wqReportItems.length > 0),expression:"wqReportItems.length > 0"}],staticClass:"subHeaderList"},[_vm._v("Water Quality Report")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.wqReportItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"cust-menu-report-items",attrs:{"to":item.url}},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}})],1)}),1)],1),_c('v-list',{staticClass:"list-inChild py-0"},[_c('v-subheader',{directives:[{name:"show",rawName:"v-show",value:(_vm.operationReportItems.length > 0),expression:"operationReportItems.length > 0"}],staticClass:"subHeaderList"},[_vm._v("Operational")]),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.operationReportItems),function(item,index){return _c('v-list-item',{key:index,staticClass:"cust-menu-report-items",attrs:{"to":item.url}},[(item.title != 'QAQC 2sec Level')?_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.title)}}):_vm._e(),(item.title == 'QAQC 2sec Level')?_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title.split("sec")[0])),_c('sup',[_vm._v("nd")]),_vm._v(" "),_c('span',{staticClass:"ml-0"},[_vm._v(_vm._s(item.title.split("sec")[1]))])]):_vm._e()],1)}),1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":"","content-class":"mt-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"white","role":"img"}},[_vm._v("mdi-account-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Profile")])])]}}],null,false,1140799911)},[_c('v-list',{staticClass:"list-inChild py-0"},[_c('v-subheader',{staticClass:"subHeaderList"},[_vm._v("Profile")]),_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{staticClass:"cust-menu-report-items",on:{"click":function($event){return _vm.goToPage('/userProfile/userProfile')}}},[_c('v-list-item-title',[_vm._v("User Profile")])],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.levelAdmin),expression:"levelAdmin"}],staticClass:"cust-menu-report-items",on:{"click":function($event){return _vm.goToPage('/systemAdmin/userManagement/userList')}}},[_c('v-list-item-title',[_vm._v("System Admin")])],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndDown)?_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""},on:{"click":function($event){_vm.mobileNavBar = !_vm.mobileNavBar}}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"white","role":"img"}},[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('span',[_vm._v("Menu")])])]}}],null,false,1535438086)}):_vm._e(),_c('v-menu',{attrs:{"origin":"center center","transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","icon":"","rounded":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{attrs:{"color":"white","role":"img"},on:{"click":_vm.signOut}},[_vm._v("mdi-logout")])],1)]}}],null,true)},[_c('span',[_vm._v("Log Out")])])]}}],null,false,2357431530)})],1)],1),_c('div',[_c('v-bottom-sheet',{model:{value:(_vm.mobileNavBar),callback:function ($$v) {_vm.mobileNavBar=$$v},expression:"mobileNavBar"}},[_c('v-sheet',{staticClass:"bottom-sheet-mobile-background"},[_c('v-expansion-panels',_vm._l((_vm.mobileitems),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{staticClass:"d-flex expansion-header",staticStyle:{"background-color":"#0081BA"}},[_c('v-card-text',{staticClass:"white--text pa-0 ma-0 font-weight-bold"},[_c('v-icon',{staticClass:"mr-2 white--text"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.title)+" ")],1)],1),_c('v-expansion-panel-content',_vm._l((item.subheader),function(list,j){return _c('v-list-item',{key:j,attrs:{"dense":"","link":"","to":list.url},on:{"click":function($event){_vm.mobileNavBar = false}}},[_c('v-card-subtitle',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(list.icon))]),_vm._v(" "+_vm._s(list.title)+" ")],1)],1)}),1)],1)}),1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }