import store from '@/store'

export default (to, from, next) => {
    //store.dispatch('checkUserLocalStorage')  //ni xperlu, sbb dia buat token digantikan dgn yg lama
    if (localStorage.user) {
        store.dispatch('refreshToken')
        next()
    } else {
        // next('/login').catch(()=>{})
        next('/').catch(()=>{})
        //next()
    }
}
